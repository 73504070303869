import {createApp} from 'vue';
import App from '@/vue/App.vue';

// Import our CSS
import '@/css/app.css';

// App main
const main = async () => {
  // Create our vue instance
  const app = createApp(App);

  // Mount the app
  return app.mount('#component-container');
};

const log = (msg) => /localhost/.test(window.location.host) && console.log(msg);

// Execute async function
main().then(() => {
  log('loaded app.ts');
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}
